import AlarmModel from '@/views/app/alarm/model/AlarmModel';
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { convertDateFormat, getDayToWeek, timeToKorean, dateTimeDiff, dateDiff, timeDiff } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'

export default class AlarmDetailViewModel {
  constructor() {
    this.categoryDataList = [];
    this.model = new AlarmModel();
    this.isModify = false;
    this.valid = {
      sendType:{
        value: 'sendType',
        isValid:false,
        validAlertDesc: '공개기간을 입력해주세요',
      },
      targetExcel:{
        value: 'targetExcel',
        valueType:'array',
        isValid:false,
        vIf:{
          valueName:'sendType',
          value:'EXCEL'
        },
        validAlertDesc: '발송대상 퍼핀유저ID 엑셀을 업로드 해주세요',
      },
      platform:{
        value: 'platform',
        isValid:false,
        validAlertDesc: '플렛폼을 선택해주세요',
      },
      targetUserRole:{
        value: 'targetUserRole',
        isValid:false,
        validAlertDesc: '회원구분을 선택해주세요',
      },
      noti_group:{
        value: 'noti_group',
        isValid:false,
        vIf:{
          valueName:'noti_usable',
          value: true
        },
        validAlertDesc: '앱내 알림 타이틀을 선택해주세요',
      },
      noti_contents:{
        value: 'noti_contents',
        isValid:false,
        vIf:{
          valueName:'noti_usable',
          value: true
        },
        validAlertDesc: '앱내 알림 본문을 입력해주세요',
      },
      noti_scheme:{
        value: 'noti_scheme',
        isValid:false,
        vIf:{
          valueName:'noti_usable',
          value: true
        },
        vIfNot:{
          valueName:'noti_buttonTitle',
          value: ''
        },
        validAlertDesc: '랜딩 URL을 입력해주세요',
      },
      noti_buttonTitle:{
        value: 'noti_buttonTitle',
        isValid:false,
        vIf:{
          valueName:'noti_usable',
          value: true
        },
        vIfNot:{
          valueName:'noti_scheme',
          value: ''
        },
        validAlertDesc: '랜딩 버튼명을 입력해주세요',
      },
      push_title:{
        value: 'push_title',
        isValid:false,
        vIf:{
          valueName:'push_usable',
          value: true
        },
        validAlertDesc: '푸시 타이틀을 입력해주세요',
      },
      push_contents:{
        value: 'push_contents',
        isValid:false,
        vIf:{
          valueName:'push_usable',
          value: true
        },
        validAlertDesc: '푸시 본문을 입력해주세요',
      },
      sendAt:{
        value: 'sendAt',
        isValid:false,
        validAlertDesc: '예약 일시를 입력해주세요',
      },
    }
  }

  init(id, copyId){
    if(id){
      this.getAlarmDetail(id,false);
    }else{
      this.model.setTargetUserList();
    }
    if(copyId){
      this.getAlarmDetail(copyId,true);
    }
  }

  validatedResult(){
    let targetUserVaild = false;
    if(this.model.sendType === 'TEST'){
      const targetUserList = this.model.targetUserList.filter(item => item != '');
      targetUserVaild = !(targetUserList.length > 0);
    }
    return pageValidated(this.valid, this.model) || targetUserVaild;
  }

  onUpdateIsMarketing(){
    this.model.setMarketingContents();
  }

  onDeleteTargetUser(index){
    this.model.targetUserList.splice(index,1);
  }
  onAddTargetUser(){
    this.model.targetUserList.push('')
  }

  onCancel(){
    const requestCancel = () => {
      this.putAlarmCancle();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `발송예약을 취소할까요?`,
      closeBtnText: '발송예약 유지',
      compeleteBtnText: '발송예약 취소',
      confirmCallBack: requestCancel,
    });
  }
  checkSendTime(){
    let result = true;
    // 등록시점보다 미래여야함
    const now = convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    const sendAt = this.model.sendAt;
    const diffDateToNow = dateDiff(sendAt, now);
    const diffTimeToNow = timeDiff(sendAt, now);
    if(diffDateToNow < 0 || diffTimeToNow === null){
      result = false;
      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `예약일시는 등록일시보다 미래여야합니다.`,
        closeBtnText: '확인',
      });
    }
    // 광고일때 오전 8시부터 오후 9까지여야함
    else if(this.model.isMarketing){
      const am8 = convertDateFormat(sendAt, 'YYYY-MM-DD') + ' 08:00:00';
      const pm9 = convertDateFormat(sendAt, 'YYYY-MM-DD') + ' 21:00:00';
      const diffStart = timeDiff(sendAt,am8);
      const diffEnd = timeDiff(pm9,sendAt);
      if(diffStart === null || diffEnd === null){
        result = false;
        store.dispatch('commonAlert/fetchAlertStart', {
          alertMsg: `광고 메세지는<br>오전 8시부터 오후 9시까지만<br>보낼 수 있습니다.`,
          closeBtnText: '확인',
        });
      }
    }
    return result
  }
  onCompleteFileSave(){
    if(!this.checkSendTime()) return;
    if(this.isModify){
      this.putAlarmDetail();
    }else{
      const requestRegister = () => {
        this.postAlarmDetail();
      }
      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `<strong>발송등록할까요?</strong><ul><li>${convertDateFormat(this.model.sendAt,'YYYY년 MM월 DD일 ') }(${getDayToWeek(this.model.sendAt)}) ${timeToKorean(this.model.sendAt)}에 발송합니다.</li><li>내용이 정확한지 꼼꼼하게 확인해주세요</li><li>광고 메시지인 경우, 알림 본문과 푸시 본문의 가장 앞에 (광고)를 반드시 넣어주세요</li></ul>`,
        closeBtnText: '취소',
        compeleteBtnText: '발송',
        confirmCallBack: requestRegister,
      });
    }
  }

  onClickCopy(){
    router.push({ name: 'APP_ALARM_REGISTER', params:{'copyId' : this.model.id} });
  }

  // 상세
  getAlarmDetail(id,isCopy){
    const path = `${apiPath.ALARM_DETAIL.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      if(isCopy){
        this.model.setCopyData(resultData)
      }else{
        this.model.setData(resultData)
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  
  // 등록
  postAlarmDetail(id){
    const path = `${apiPath.ALARM_REGISTER}`;
    const data = this.model.getData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '알림예약이 등록되었습니다');
      router.push({ name: 'APP_ALARM_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  
  // 수정
  putAlarmDetail(){
    const path = `${apiPath.ALARM_MODIFY.format(this.model.id)}`;
    const data = this.model.getData();
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '알림예약이 수정되었습니다');
      router.push({ name: 'APP_ALARM_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 발송예약 취소
  putAlarmCancle(){
    const path = `${apiPath.ALARM_MODIFY.format(this.model.id)}`;
    const data = this.model.getCancelData();
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '발송예약이 취소되었습니다');
      router.push({ name: 'APP_ALARM_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}