import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'

const defaultDivider = process.env.NODE_ENV === 'production' ? 2000 : 1000;

export default class AlarmModel {
  constructor(){
    this.objRaw = {};
    this.id = null;
    this.tabStatus = '';

    this.isMarketing = false; // 광고유무

    this.sendType = ''; // 발송타겟 [ ALL : 전체발송 / EXCEL : 퍼핀유저 ID로 특정(엑셀업로드) / TEST : 테스트 발송(직원들만) ]
    this.targetExcel = []; // 업로드할 엑셀 파일
    this.targetPath = ''; // 업로드한 엑셀 S3 path
    this.targetUserListProduction = [
      83466, // (박하영 / 010-9241-0541) - 부
      14480, // (위단비 / 010-2691-9686) - 부
      75, // (강정석 / 010-9915-3716) - 자
      86, // (이민희 / 010-2874-1401) - 부
      5, // (강대명)
      227082, // (김예은 / 010-8802-3038 ) -자
    ];
    this.targetUserListDev = [
      1
    ];
    this.targetUserList = []; // 테스트 발송 유저 목록
    this.platform = ''; // 플렛폼 [ ALL / IOS / ANDROID ]
    this.targetUserRole = ''; // 회원구분 [ ALL / PARENTS / CHILD ]

    this.divider = defaultDivider; // 스케쥴 분할 유저수
    this.startRange = null; // 분할 시작 유저 ID
    this.endRange = null; // 분할 끝 유저 ID

    this.image = ''; // 이미지
    this.imageList = []; // 이미지

    this.noti_usable = false; // 알림 사용 유무
    this.noti_group = ''; // 알림 타이틀 [ GUIDE / FIRFINCARD / FIRFINCITY ]
    this.noti_contents = ''; // 알림 본문
    this.noti_scheme = ''; // 알림 랜딩 URL
    this.noti_buttonTitle = ''; // 알림 랜딩 버튼명

    this.push_usable = false; // 푸시 사용 유무
    this.push_title = ''; // 푸시 타이틀
    this.push_contents = ''; // 푸시 본문
    this.push_scheme = ''; // 푸시 랜딩 URL

    this.sendAt = ''; // 발송일정
  }
  setTargetUserList(){
    if(process.env.NODE_ENV === 'production'){
      this.targetUserList = this.targetUserListProduction;
    }else{
      this.targetUserList = this.targetUserListDev;
    }
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, status, isMarketing, targetPath, targetUserList, platform, target, divider, startRange, endRange, image, noti, push, sendAt } = obj;
    this.id = id;
    this.status = status;

    this.isMarketing = isMarketing;

    if(targetPath){
      this.sendType = 'EXCEL';
      this.targetExcel = [
        {
          src : targetPath,
        }
      ];
      this.targetPath = targetPath;
    }else if(targetUserList && targetUserList.length > 0){
      this.sendType = 'TEST';
      this.targetUserList = targetUserList;
    }else{
      this.sendType = 'ALL';
    }

    this.platform = platform;
    this.targetUserRole = target;

    this.divider = divider === null ? defaultDivider : divider;
    this.startRange = startRange;
    this.endRange = endRange;

    this.image = image;
    if(image){
      this.imageList = [
        {
          src : image,
        }
      ]
    }

    this.noti_usable = noti.usable;
    this.noti_group = noti.group;
    this.noti_contents = noti.contents;
    this.noti_scheme = noti.scheme;
    this.noti_buttonTitle = noti.buttonTitle;

    this.push_usable = push.usable;
    this.push_title = push.title;
    this.push_contents = push.contents;
    this.push_scheme = push.scheme;

    this.sendAt = convertDateFormat(sendAt, 'YYYY-MM-DD HH:mm:ss');
  }
  setCopyData(obj){
    this.setData(obj);
    this.status = "REGISTERED";
    this.sendType = '';
    this.sendAt = '';
  }
  setMarketingContents(){
    // 앞 공백 제거
    this.noti_contents = this.noti_contents.replace(/^\s*/, "");
    this.push_contents = this.push_contents.replace(/^\s*/, "");

    const text_ad = '(광고)';
    const isHasTextAdNotiContents = this.noti_contents.indexOf(text_ad) === 0;
    const isHasTextAdPushContents = this.push_contents.indexOf(text_ad) === 0;

    if(this.isMarketing){
      if(!isHasTextAdNotiContents){
        this.noti_contents = text_ad + ' ' + this.noti_contents;
      }
      if(!isHasTextAdPushContents){
        this.push_contents = text_ad + ' ' + this.push_contents;
      }
    }else{
      if(isHasTextAdNotiContents){
        this.noti_contents = this.noti_contents.replace(text_ad, '');
      }
      if(isHasTextAdPushContents){
        this.push_contents = this.push_contents.replace(text_ad, '');
      }
    }

  }
  getData(){
    let obj = {
      isMarketing : this.isMarketing,
      platform : this.platform,
      target : this.targetUserRole,

      divider : this.divider ? Number(String(this.divider).replace(',','')) : null,
      image : this.imageList.length > 0 ? this.imageList[0].src : '',

      noti : {
        usable : this.noti_usable,
        group : this.noti_group,
        contents : this.noti_contents,
        scheme : this.noti_scheme,
        buttonTitle : this.noti_buttonTitle,
      },

      push : {
        usable : this.push_usable,
        title : this.push_title,
        contents : this.push_contents,
        scheme : this.push_scheme,
      },

      sendAt : convertDateFormat(this.sendAt+' 00:00:00','YYYY-MM-DDTHH:mm:ss.SSS'),
      status : "REGISTERED"
    }
    if(this.id){
      obj.id = this.id;
    }

    if(!this.noti_group){
      obj.noti.group = null;
    }

    if(this.sendType === 'EXCEL'){
      obj.targetPath = this.targetExcel.length > 0 ? this.targetExcel[0].src : '';
    }else if(this.sendType === 'TEST'){
      let targetUserList = this.targetUserList.filter(item => item != '').filter(item => Number(String(item).replace(',',''))).map(item => {
        return Number(String(item).replace(',',''))
      }) ;
      const set = new Set(targetUserList);
      const uniqueArr = [...set];
      obj.targetUserList = uniqueArr;
    }

    return obj;
  }
  getCancelData(){
    let obj = this.objRaw;
    obj.status = "UNREGISTERED";
    return obj
  }
}